import createTheme from "@mui/material/styles/createTheme";

export const SPACING = 6;

export const theme = createTheme({
  spacing: SPACING,
  palette: {
    primary: { main: "#009F4D", light: "#8BD800" },
    secondary: { main: "#7E7E7E" },
    text: { primary: "#7E7E7E", secondary: "#f6f8fc" },
    error: { main: "#AD2121" },
    success: { main: "#1E7B41" },
    warning: { main: "#E09640" },
  },
  typography: {
    h1: {
      fontWeight: 400,
      color: "#009F4D",
      fontSize: "29px",
      lineHeight: "36px",
      marginBottom: "42px",
    },
    h2: {
      fontWeight: 400,
      color: "#009F4D",
      fontSize: "24px",
      lineHeight: "24px",
      marginBottom: "42px",
    },
    h3: {
      fontWeight: 400,
      color: "#009F4D",
      fontSize: "23px",
      lineHeight: "30px",
      marginBottom: "42px",
    },
    h4: {
      fontWeight: 700,
      color: "#009F4D",
      fontSize: "18px",
      lineHeight: "24px",
      marginBottom: "11px",
      fontFamily: "PFBeauSansPro-SemiBold",
    },
    subtitle1: {
      fontFamily: "PFBeauSansPro-SemiBold",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    body1: {
      // color: "#A6A6A6",
      color: "#7E7E7E",
      fontSize: "18px",
      lineHeight: "24px",
    },
    body2: {
      // color: "#A6A6A6",
      color: "#7E7E7E",
      fontSize: "16px",
      lineHeight: "18px",
    },
    caption: { color: "#A0A09B", fontSize: "13px", lineHeight: "24px" },
    fontFamily: [
      '"PF BeauSans Pro"',
      '"Inter"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          lineHeight: "24px",
          textTransform: "initial",
          padding: `${SPACING * 2}px ${SPACING * 4}px`,
        },
        contained: {
          borderRadius: "7px",
          backgroundColor: "#3680C7",
          color: "#FFF",
          "&:hover": {
            backgroundColor: "#4580C2",
            transform: "translateY(-1px)",
          },
        },
        containedSecondary: {
          backgroundColor: "#009F4D",
          "&:hover": {
            backgroundColor: "#8EC162",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          color: "#3680C7",
          "&:hover": { cursor: "pointer" },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: "#E5E5E6",
          backgroundColor: "#FDFDFD",
          borderRadius: 4,
          height: 41,
          "& legend": {
            width: 0,
          },
          "& .MuiInputBase-multiline": {
            height: "auto",
          },
          "& input": {
            fontSize: "14px",
            padding: `${SPACING * 2}px ${SPACING * 2.5}px`,
            lineHeight: "17px",
            height: "17px",
            backgroundColor: "#FDFDFD",
            borderRadius: "4px",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          marginBottom: `${SPACING}px`,
          position: "initial",
          transform: "none",
          transition: "none",
          maxWidth: "100%",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: { color: "#A6A6A6" },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        icon: <></>,
      },
      styleOverrides: {
        root: {
          border: "1px solid #A6A6A6",
          borderRadius: "4px",
          boxSizing: "border-box",
          width: 16,
          height: 16,
          fontSize: 16,
          "&.Mui-checked": {
            border: "1px solid #009F4D",
          },
        },
      },
    },
  },
});
