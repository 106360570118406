import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function GDPRFR() {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="body1">
          VPK Paper Normandie accorde une grande importance à la protection des
          données personnelles de ses visiteurs.
        </Typography>
        <Typography variant="body1">
          Nous sommes responsables du traitement de ces données. Les présentes
          dispositions ont pour but de vous informer de la manière dont nous
          utilisons et protégeons vos données personnelles ainsi que les raisons
          qui font que nous traitons ces données.
        </Typography>
        <Typography variant="body1">
          Il est rappelé que de nouvelles dispositions européennes sur la
          protection des données entreront en vigueur à compter du 25 mai 2018.
          Il s’agit du Règlement européen du 27 avril 2016 qui établit des
          règles relatives à la protection des personnes physiques à l'égard du
          traitement des données à caractère personnel et des règles relatives à
          la libre circulation de ces données.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Responsable du traitement
        </Typography>
        <Typography variant="body2">
          DPO - Délégué à la Protection des Données
          <br />
          VPK Paper Normandie
          <br />
          Zone lndustrielle du Clos Pré BP 4<br />
          27460 Alizay
          <br />
          FRANCE
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Données récoltées en tant que responsable de traitement des données
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie intervient en tant que responsable de traitement
          des données de ses Utilisateurs et à ce titre collecte des données
          pour des besoins de service.
        </Typography>
        <Typography variant="body1">
          Nous sommes amené à collecter tout ou partie des données suivantes :
        </Typography>
        <Typography variant="body2">
          - Société
          <br />
          - Adresse mail
          <br />
          - Nom et Prénom
          <br />
          - Téléphone
          <br />- Date de visite sur site
        </Typography>
        <Typography variant="body1">
          Ces données sont directement renseignées par l’Utilisateur lors de son
          inscription, VPK Paper Normandie ne collecte aucune donnée à caractère
          personnel de façon implicite.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Objectif des données personnelles collectées
        </Typography>
        <Typography variant="body2">
          - Sécurité et conformité des visiteurs sur site
          <br />- Pour communiquer avec les futurs visiteurs
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Communication des données personnelles de l’Utilisateur
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie est susceptible de partager les données collectées
          avec VPK Group, les organismes publics (Police, Justice, Services
          Fiscaux, Services Sociaux…) sur demande officielle et dans les limites
          et le respect des procédures légales applicables en France.
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie ne fournit ni ne vend les données de ses
          Utilisateurs à aucun autre organisme ou société et ne fait pas
          réaliser des traitements sur les données de ses Utilisateurs par des
          sous-traitants.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Droits de l’Utilisateur
        </Typography>
        <Typography variant="body1">
          Conformément à la réglementation en vigueur, l’Utilisateur dispose de
          différents droits à savoir :
        </Typography>
        <Typography variant="body2">
          Droit d’accès (chapitre 3 article 15 du RGPD)
          <br />
          Droit de rectification (chapitre 3 article 16 du RGPD)
          <br />
          Droit à l’oubli (chapitre 3 article 17 du RGPD)
          <br />
          Droit à la limitation du traitement (chapitre 3 article 18 du RGPD)
          <br />
          Droit d’opposition (chapitre 3 article 21 du RGPD)
          <br />
          Droit de retirer votre consentement (chapitre 2 article 7 du RGPD)
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Durée du traitement et conservation des données :
        </Typography>
        <Typography variant="body1">
          Maximum 1 an pour les visiteurs et les contractants.
        </Typography>
      </Stack>
    </Stack>
  );
}
