declare type Props = {
  color?: string;
};

export const CaretIcon = ({ color = "#A6A6A6" }: Props) => (
  <svg
    width="17"
    height="7"
    viewBox="0 0 17 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.63452 7L0.407282 0.382353L16.8618 0.382353L8.63452 7Z"
      fill={color}
    />
  </svg>
);
