import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NOW } from "../../../config";
import { normalizeKey } from "../../../config/i18n";

export default function DateTimeField({
  name = "date",
  id = "date",
  label = "",
  placeholder,
  ...rest
}: TextFieldProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <TextField
      id={id}
      label={label || t("form.fields.appointment.placeholder")}
      type="datetime-local"
      fullWidth
      InputLabelProps={{ shrink: true }}
      inputProps={{
        // step: 300,
        min: NOW.format("YYYY-MM-DDTHH:mm"),
      }}
      error={!!errors[name]?.message}
      helperText={
        !!errors[name]?.message
          ? errors[name]?.type === "typeError"
            ? t("form.fields.appointment.format")
            : t(normalizeKey(errors[name]?.message as string))
          : " "
      }
      {...rest}
      {...register(name)}
    />
  );
}
