import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function GDPRNL() {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="body1">
          VPK Paper Normandie hecht veel belang aan de bescherming van de
          persoonsgegevens van haar bezoekers.
        </Typography>
        <Typography variant="body1">
          Wij zijn verantwoordelijk voor de verwerking van deze gegevens. Deze
          bepalingen zijn bedoeld om u te informeren over de manier waarop wij
          uw persoonsgegevens gebruiken en beschermen en over de redenen waarom
          wij deze gegevens verwerken.
        </Typography>
        <Typography variant="body1">
          Er wordt aan herinnerd dat vanaf 25 mei 2018 nieuwe Europese
          bepalingen inzake gegevensbescherming in werking treden. Dit is de
          Europese Verordening van 27 april 2016 die regels vastlegt met
          betrekking tot de bescherming van natuurlijke personen met betrekking
          tot de verwerking van persoonsgegevens en de regels met betrekking tot
          het vrije verkeer van dergelijke gegevens.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Verwerkingsverantwoordelijke
        </Typography>
        <Typography variant="body2">
          DPO - functionaris voor gegevensbescherming
          <br />
          VPK Paper Normandie
          <br />
          Zone lndustrielle du Clos Pré BP 4<br />
          27460 Alizay
          <br />
          FRANCE - FRANKRIJK
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Gegevens verzameld als verwerkingsverantwoordelijke
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie treedt op als gegevensverwerkingsverantwoordelijke
          voor haar Gebruikers en verzamelt als zodanig gegevens voor
          servicedoeleinden.
        </Typography>
        <Typography variant="body1">
          We verzamelen alle of een deel van de volgende gegevens:
        </Typography>
        <Typography variant="body2">
          - Bedrijf
          <br />
          - Mail adres
          <br />
          - Voor-en achternaam
          <br />
          - Telefoon
          <br />- Datum bezoek site
        </Typography>
        <Typography variant="body1">
          Deze gegevens worden rechtstreeks door de Gebruiker ingevoerd bij de
          registratie, VPK Paper Normandie verzamelt impliciet geen
          persoonsgegevens.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Doel van de verzamelde persoonsgegevens
        </Typography>
        <Typography variant="body2">
          - Veiligheid en naleving van bezoekers ter plaatse
          <br />- Om te communiceren met toekomstige bezoekers
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Mededeling van de persoonlijke gegevens van de gebruiker
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie kan de verzamelde gegevens delen met VPK Group,
          overheidsinstanties (politie, justitie, belastingdienst, sociale
          dienst, enz.) op officieel verzoek en binnen de grenzen en naleving
          van de wettelijke procedures die van toepassing zijn in Frankrijk.
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie verstrekt of verkoopt de gegevens van haar
          Gebruikers niet aan enige andere organisatie of bedrijf en laat de
          gegevens van haar Gebruikers niet verwerken door onderaannemers.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Gebruikersrechten
        </Typography>
        <Typography variant="body1">
          In overeenstemming met de geldende regelgeving heeft de Gebruiker
          verschillende rechten, namelijk:
        </Typography>
        <Typography variant="body2">
          Recht op inzage (hoofdstuk 3 artikel 15 AVG)
          <br />
          Recht op rectificatie (hoofdstuk 3 artikel 16 AVG)
          <br />
          Recht om vergeten te worden (hoofdstuk 3 artikel 17 AVG)
          <br />
          Recht op beperking van verwerking (hoofdstuk 3 artikel 18 AVG)
          <br />
          Recht van verzet (hoofdstuk 3 artikel 21 van de AVG)
          <br />
          Recht om uw toestemming in te trekken (Hoofdstuk 2 Artikel 7 AVG)
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Duur van verwerking en bewaren van gegevens
        </Typography>
        <Typography variant="body1">
          Maximaal 1 jaar voor bezoekers en aannemers.
        </Typography>
      </Stack>
    </Stack>
  );
}
