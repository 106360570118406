import France from "./france.png";
import UnitedKingdom from "./united_kingdom.png";
import Netherlands from "./netherlands.png";
import Germany from "./germany.png";

declare type Props = {
  country: "FR" | "NL" | "UK" | "DE";
  width?: number;
  height?: number;
};

export default function Flag({ country, width, height }: Props) {
  if (country === "FR")
    return (
      <img src={France} alt="France's flag" width={width} height={height} />
    );
  if (country === "NL")
    return (
      <img
        src={Netherlands}
        alt="Netherland's flag"
        width={width}
        height={height}
      />
    );
  if (country === "UK")
    return (
      <img
        src={UnitedKingdom}
        alt="UnitedKingdom's flag"
        width={width}
        height={height}
      />
    );
  // TEMPORARY
  if (country === "DE")
    return (
      <img src={Germany} alt="Germany's flag" width={width} height={height} />
    );
  return <></>;
}
