import { useState, useRef, useMemo } from "react";
import { Carousel } from "react-responsive-carousel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import instructionsFR from "../../config/translations/instructions/fr.json";
import instructionsEN from "../../config/translations/instructions/en.json";
import instructionsDE from "../../config/translations/instructions/de.json";
import instructionsNL from "../../config/translations/instructions/nl.json";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./custom.css";
import InstructionItem from "./InstructionItem";
import InstructionArrow from "./InstructionArrow";

declare type Props = {
  onStepCompleted: (current: number) => void;
};

export default function InstructionsSlider({ onStepCompleted }: Props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { i18n, t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const instructions = useMemo(() => {
    if (i18n.language.includes("fr")) return instructionsFR;
    if (i18n.language.includes("en")) return instructionsEN;
    if (i18n.language.includes("de")) return instructionsDE;
    if (i18n.language.includes("nl")) return instructionsNL;
    return instructionsFR;
  }, [i18n.language]);

  const onSlideChange = (index: number) => {
    setCurrentSlide(index);
    onStepCompleted(index + 1);
    containerRef?.current?.scrollIntoView();
  };

  const handleNext = () => {
    const _current =
      currentSlide + 1 > instructions.length ? currentSlide : currentSlide + 1;
    setCurrentSlide(_current);
    containerRef?.current?.scrollIntoView();
  };

  const handlePrevious = () => {
    setCurrentSlide(currentSlide - 1 < 0 ? currentSlide : currentSlide - 1);
    containerRef?.current?.scrollIntoView();
  };

  return (
    <Box sx={{ width: "100%" }} ref={containerRef}>
      <Carousel
        autoPlay={false}
        dynamicHeight={true}
        emulateTouch={true}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        onChange={onSlideChange}
        selectedItem={currentSlide}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return hasPrev ? (
            <InstructionArrow direction="left" onClick={clickHandler} />
          ) : null;
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return hasNext ? (
            <InstructionArrow direction="right" onClick={clickHandler} />
          ) : null;
        }}
      >
        {instructions.map((step, index) => (
          <Grid
            container
            spacing={{ xs: 7, sm: 5, md: 3 }}
            sx={{ width: "100%", my: 15, mx: 0, px: "45px" }}
            key={step.id}
          >
            {step.description && (
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ py: 2 }}>
                  {step.description}
                </Typography>
              </Grid>
            )}

            {step.content.map((instruction) => (
              <InstructionItem instruction={instruction} key={instruction.id} />
            ))}
          </Grid>
        ))}
      </Carousel>
      <Stack
        direction="row"
        sx={{ width: "100%", mt: { xs: 2, md: 7 } }}
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 2, sm: 4, md: 10, lg: 14 }}
          component={Paper}
          sx={{ backgroundColor: "#FFF", borderRadius: 1 }}
        >
          <Button
            variant="text"
            onClick={handlePrevious}
            disabled={currentSlide === 0}
          >
            &lt; {t("common.action.previous")}
          </Button>
          <Typography variant="body2">
            {currentSlide + 1} / {instructions.length}
          </Typography>
          <Button
            variant="text"
            onClick={handleNext}
            disabled={currentSlide >= instructions.length - 1}
          >
            {t("common.action.next")} &gt;
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
