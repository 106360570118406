import { SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useOnboarding from "../../../hooks/useOnboarding";

declare type Props = {
  label: string;
  value?: string;
  defaultValue?: string;
  sx?: SxProps;
};

const VPKCompanyToString = (company: string = "") => {
  switch (company) {
    case "PAPER":
      return "VPK PAPER NORMANDIE";
    case "PACKAGING":
      return "VPK PACKAGING ALIZAY";
    case "GROUP":
      return "VPK GROUP";
    default:
      return "VPK PAPER NORMANDIE";
  }
};

const DataRow = ({ label, value = "", defaultValue = "", sx = {} }: Props) => (
  <Typography variant="subtitle2" sx={sx}>
    {label} :&nbsp;
    <Typography
      variant="subtitle1"
      component="span"
      sx={{
        fontStyle: value ? "normal" : "italic",
      }}
    >
      {value || defaultValue}
    </Typography>
  </Typography>
);

export default function FinalStep() {
  const { t } = useTranslation();
  const { submittedValues } = useOnboarding();

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography variant="h1" sx={{ textAlign: "center", mb: 2 }}>
        {t("home.finalStep.title")}
      </Typography>
      <Typography sx={{ textAlign: "center", whiteSpace: "pre-line" }}>
        {t("home.finalStep.description")}
      </Typography>
      <Stack
        sx={{
          mt: 6,
          py: 7,
          backgroundColor: "#F7F7F7",
          width: "100vw",
        }}
        alignItems="center"
      >
        <Stack
          sx={{
            maxWidth: "1440px",
            width: "100%",
            px: { xs: 4, sm: 8, md: 23 },
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h3" sx={{ mb: 3 }}>
            {t("home.finalStep.summary.title")}
          </Typography>
          <Typography variant="h4" sx={{ mb: 2 }}>
            {t("home.finalStep.summary.me")}
          </Typography>
          <DataRow
            label={t("home.finalStep.summary.lastname")}
            value={submittedValues?.user.lastname}
          />
          <DataRow
            label={t("home.finalStep.summary.firstname")}
            value={submittedValues?.user.firstname}
          />
          <DataRow
            label={t("home.finalStep.summary.email")}
            value={submittedValues?.user.email}
          />
          <DataRow
            label={t("home.finalStep.summary.phone")}
            value={submittedValues?.user.phone}
            defaultValue={t("home.finalStep.summary.noData")}
          />
          <DataRow
            label={t("home.finalStep.summary.company")}
            value={submittedValues?.user.company}
            defaultValue={t("home.finalStep.summary.noData")}
          />
          <Typography variant="h4" sx={{ my: 2 }}>
            {t("home.finalStep.summary.employee")}
          </Typography>
          <DataRow
            label={t("home.finalStep.summary.lastname")}
            value={submittedValues?.employee.lastname}
          />
          <DataRow
            label={t("home.finalStep.summary.firstname")}
            value={submittedValues?.employee.firstname}
          />
          <DataRow
            label={t("home.finalStep.summary.company")}
            value={VPKCompanyToString(submittedValues?.employee.company)}
          />
          <DataRow
            label={t("home.finalStep.summary.appointmentLabel")}
            value={dayjs(submittedValues?.appointment).format(
              "DD/MM/YYYY [–] HH[:]mm"
            )}
          />
          <DataRow
            label={t("home.finalStep.summary.validationDateLabel")}
            value={submittedValues?.validationDate.format(
              "DD/MM/YYYY [–] HH[:]mm"
            )}
            sx={{ mt: 3 }}
          />
        </Stack>
      </Stack>
      <Stack sx={{ maxWidth: "1440px", width: "100%", mt: 7 }}>
        <Button
          variant="contained"
          href="https://vpkpapiernormandie.com/"
          target="_blank"
          sx={{ alignSelf: "center" }}
        >
          {t("home.finalStep.visitCorpo")}
        </Button>
      </Stack>
    </Stack>
  );
}
