import Box from "@mui/material/Box";

declare type Props = {
  width?: number;
  src: string;
  alt?: string;
};

export default function Pictogram({ src }: Props) {
  return (
    <Box
      sx={{
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
      }}
    />
  );
}
