import { useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

import DE from "./translations/DE";
import EN from "./translations/EN";
import FR from "./translations/FR";
import NL from "./translations/NL";

declare type Props = {
  open: boolean;
  handleClose: () => void;
};

export default function GDPRDialog({ open, handleClose }: Props) {
  const { i18n, t } = useTranslation();

  const content = useMemo(() => {
    if (i18n.language.includes("fr")) return <FR />;
    if (i18n.language.includes("en")) return <EN />;
    if (i18n.language.includes("de")) return <DE />;
    if (i18n.language.includes("nl")) return <NL />;
    return <FR />;
  }, [i18n.language]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      scroll="paper"
      onClose={handleClose}
    >
      <DialogTitle>{t("app.gdpr.title")}</DialogTitle>
      <DialogContent dividers={true}>{content}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common.action.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
