import { useState } from "react";
import MUILink from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TermsDialog from "../Dialogs/TermsDialog";
import FooterItem from "./FooterItem";
import GDPRDialog from "../Dialogs/GDPRDialog";

export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [GDPRDialogOpen, setGDPRDialogOpen] = useState(false);

  const handleCloseTermsDialog = () => {
    setTermsDialogOpen(false);
  };

  const handleOpenTermsDialog = () => {
    setTermsDialogOpen(true);
  };

  const handleCloseGDPRDialog = () => {
    setGDPRDialogOpen(false);
  };
  const handleOpenGDPRDialog = () => {
    setGDPRDialogOpen(true);
  };

  const handleRegistration = () => {
    navigate("/registration");
  };

  return (
    <>
      <Stack
        justifyContent="center"
        flex={1}
        direction="row"
        sx={{
          backgroundColor: "#414141",
          mt: 4,
          minHeight: 160,
          position: "relative",
          backgroundImage: {
            xs: 'url("/vertical_footer.svg")',
            md: 'url("/footer.png")',
          },
          backgroundPosition: { xs: "center right", md: "bottom right" },
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "contain", md: "40% auto" },
          px: { xs: 4, sm: 8, md: 0 },
        }}
      >
        <Stack
          sx={{ width: "100%", maxWidth: "1440px" }}
          justifyContent="flex-start"
          direction={{ xs: "column", sm: "row" }}
        >
          <Stack spacing={1} sx={{ pt: 6, pb: 3 }} direction="column" flex={2}>
            <FooterItem onClick={handleOpenTermsDialog}>
              {t("app.footer.terms")}
            </FooterItem>
            <FooterItem onClick={handleOpenGDPRDialog}>
              {t("app.footer.gdpr")}
            </FooterItem>
            <FooterItem onClick={handleRegistration}>
              {t("app.footer.registration")}
            </FooterItem>
            <MUILink
              href="https://vpkpapiernormandie.com"
              variant="body2"
              target="_blank"
              sx={{
                color: "#FFF",
                fontSize: 14,
                pl: { xs: 0, md: 23 },
                fontWeight: "initial",
              }}
            >
              {t("app.footer.corporate")}
            </MUILink>
          </Stack>
          <Stack
            alignItems={{ xs: "left", sm: "center" }}
            sx={{ pt: 6, pb: 3 }}
            flex={2}
          >
            <Typography variant="body2" sx={{ color: "#FFF", fontSize: 14 }}>
              {t("app.footer.company.addressLine1")}
            </Typography>
            <Typography variant="body2" sx={{ color: "#FFF", fontSize: 14 }}>
              {t("app.footer.company.addressLine2")}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#FFF", fontSize: 14, mt: 2 }}
            >
              {t("app.footer.company.phone")}
            </Typography>
          </Stack>
          <Box flex={2} />
        </Stack>
      </Stack>
      <TermsDialog
        open={termsDialogOpen}
        handleClose={handleCloseTermsDialog}
      />
      <GDPRDialog open={GDPRDialogOpen} handleClose={handleCloseGDPRDialog} />
    </>
  );
}
