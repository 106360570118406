import { Typography } from "@mui/material";

declare type Props = {
  children: string | JSX.Element | JSX.Element[];
  onClick?: () => void;
};

export default function FooterItem({ children, onClick }: Props) {
  return (
    <Typography
      variant="body2"
      sx={{
        color: "#FFF",
        fontSize: 14,
        pl: { xs: 0, md: 23 },
        cursor: onClick ? "pointer" : "default",
        "&:hover": {
          textDecoration: onClick ? "underline" : "none",
        },
      }}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
}
