import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import HomePage from "./HomePage";
import Layout from "./Layout";
import RegistrationPage from "./RegistrationPage";

export default function Router() {
  let router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<Layout />}>
          <Route path="*" element={<HomePage />} />
          <Route path="/registration" element={<RegistrationPage />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}
