import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function GDPRDE() {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="body1">
          VPK Paper Normandie legt großen Wert auf den Schutz der
          personenbezogenen Daten seiner Besucher.
        </Typography>
        <Typography variant="body1">
          Wir sind für die Verarbeitung dieser Daten verantwortlich. Diese
          Bestimmungen sollen Sie darüber informieren, wie wir Ihre
          personenbezogenen Daten verwenden und schützen, sowie die Gründe, aus
          denen wir diese Daten verarbeiten.
        </Typography>
        <Typography variant="body1">
          Es wird daran erinnert, dass ab dem 25. Mai 2018 neue europäische
          Datenschutzbestimmungen in Kraft treten. Dies ist die Europäische
          Verordnung vom 27. April 2016, die Vorschriften zum Schutz natürlicher
          Personen bei der Verarbeitung personenbezogener Daten und der
          Vorschriften über den freien Datenverkehr.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Datencontroller
        </Typography>
        <Typography variant="body2">
          DPO - Datenschutzbeauftragter
          <br />
          VPK Paper Normandie
          <br />
          Zone lndustrielle du Clos Pré BP 4<br />
          27460 Alizay
          <br />
          FRANCE - FRANKREICH
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Als Datenverantwortlicher erhobene Daten
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie fungiert als Datenverarbeitungsmanager für seine
          Benutzer und sammelt als solcher Daten für Servicezwecke.
        </Typography>
        <Typography variant="body1">
          Wir erheben alle oder einen Teil der folgenden Daten:
        </Typography>
        <Typography variant="body2">
          - Unternehmen
          <br />
          - Mail Adresse
          <br />
          - Vollständiger Name
          <br />
          - Telefon
          <br />- Datum des Besuchs vor Ort
        </Typography>
        <Typography variant="body1">
          Diese Daten werden direkt vom Benutzer bei der Registrierung
          eingegeben, VPK Paper Normandie erhebt implizit keine
          personenbezogenen Daten.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Zweck der erhobenen personenbezogenen Daten
        </Typography>
        <Typography variant="body2">
          - Besuchersicherheit und Compliance vor Ort
          <br />- Um mit zukünftigen Besuchern zu kommunizieren
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Übermittlung der personenbezogenen Daten des Benutzers
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie kann die gesammelten Daten auf offizielle Anfrage
          und im Rahmen und unter Einhaltung der in Frankreich geltenden
          gesetzlichen Verfahren an die VPK-Gruppe, öffentliche Stellen
          (Polizei, Justiz, Steuerbehörden, Sozialdienste usw.) weitergeben.
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie gibt oder verkauft die Daten seiner Benutzer nicht
          an andere Organisationen oder Unternehmen und lässt die Daten seiner
          Benutzer nicht von Subunternehmern verarbeiten.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Nutzerrechte
        </Typography>
        <Typography variant="body1">
          Gemäß den geltenden Vorschriften hat der Benutzer verschiedene Rechte,
          nämlich:
        </Typography>
        <Typography variant="body2">
          Auskunftsrecht (Kapitel 3 Artikel 15 DSGVO)
          <br />
          Recht auf Berichtigung (Kapitel 3 Artikel 16 DSGVO)
          <br />
          Recht auf Vergessenwerden (Kapitel 3 Artikel 17 DSGVO)
          <br />
          Recht auf Einschränkung der Verarbeitung (Kapitel 3 Artikel 18 DSGVO)
          <br />
          Widerspruchsrecht (Kapitel 3 Artikel 21 DSGVO)
          <br />
          Recht auf Widerruf Ihrer Einwilligung (Kapitel 2 Artikel 7 DSGVO)
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Dauer der Verarbeitung und Speicherung der Daten
        </Typography>
        <Typography variant="body1">
          Maximal 1 Jahr für Besucher und Auftragnehmer.
        </Typography>
      </Stack>
    </Stack>
  );
}
