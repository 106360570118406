import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SPACING } from "../../config/theme";
import Pictogram from "../Pictogram";

declare type Props = {
  instruction: {
    id: string;
    text: string;
    pictures: string[];
  };
};

export default function InstructionItem({ instruction }: Props) {
  const countPictures = instruction.pictures.length;
  const containerWidth = { xs: 80, sm: 100 };
  const containerHeight = {
    xs:
      countPictures > 0
        ? 80 * countPictures + (SPACING * 2 * countPictures - 1)
        : 80,
    sm:
      countPictures > 0
        ? 100 * countPictures + (SPACING * 2 * countPictures - 1)
        : 100,
  };

  return (
    <Grid item xs={12} md={6} sx={{ minHeight: 140 }}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        spacing={4}
        sx={{ width: "100%" }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: containerWidth, height: containerHeight }}
        >
          {countPictures > 0 && (
            <Stack
              sx={{ height: containerHeight, width: containerWidth }}
              justifyContent="space-between"
            >
              {instruction.pictures.map((picture, index) => (
                <Box
                  sx={{ width: containerWidth, height: containerWidth }}
                  key={index}
                >
                  <Pictogram src={picture} />
                </Box>
              ))}
            </Stack>
          )}
        </Stack>
        <Stack justifyContent="center" sx={{ pb: "11px" }}>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "pre-line",
              flex: 2,
              textAlign: "left",
              width: "100%",
            }}
          >
            {instruction.text}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
}
