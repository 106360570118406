import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InstructionsSlider from "../../../components/InstructionsSlider";
import instructionsFR from "../../../config/translations/instructions/fr.json";
import instructionsEN from "../../../config/translations/instructions/en.json";
import instructionsDE from "../../../config/translations/instructions/de.json";
import instructionsNL from "../../../config/translations/instructions/nl.json";
import useOnboarding from "../../../hooks/useOnboarding";

declare type Props = {
  onValidate: () => void;
};

export default function SecondStep({ onValidate }: Props) {
  const { markItemsAsRead, hasReadItems } = useOnboarding();
  const [lastReadStep, setLastReadStep] = useState(1);
  const { i18n, t } = useTranslation();

  const instructions = useMemo(() => {
    if (i18n.language.includes("fr")) return instructionsFR;
    if (i18n.language.includes("en")) return instructionsEN;
    if (i18n.language.includes("de")) return instructionsDE;
    if (i18n.language.includes("nl")) return instructionsNL;
    return instructionsFR;
  }, [i18n.language]);

  const STEP_LENGTH = instructions.length;

  const onStepCompleted = (activeStep: number) => {
    const newValue =
      activeStep > lastReadStep ? lastReadStep + 1 : lastReadStep;
    setLastReadStep(newValue);
    if (newValue === STEP_LENGTH) {
      markItemsAsRead();
    }
  };

  const readingState = Math.round((lastReadStep / STEP_LENGTH) * 100);

  const handleStepValidation = () => {
    if (hasReadItems) {
      onValidate();
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography variant="h1" sx={{ textAlign: "center", mb: 2 }}>
        {t("home.secondStep.title")}
      </Typography>
      <Typography sx={{ textAlign: "center", whiteSpace: "pre-line" }}>
        {t("home.secondStep.description")}
      </Typography>
      <Box sx={{ width: "100%", mt: 4 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          {t("home.secondStep.readStatus")} {readingState}%
        </Typography>
        <LinearProgress
          sx={{ height: 10, borderRadius: 2 }}
          variant="determinate"
          value={readingState}
        />
      </Box>
      <Stack
        sx={{
          mt: 6,
          py: 7,
          backgroundColor: "#F7F7F7",
          width: "100vw",
        }}
        alignItems="center"
      >
        <Stack
          sx={{
            maxWidth: "1440px",
            width: "100%",
            boxSizing: "border-box",
            px: { xs: 4, sm: 8, md: 23 },
          }}
          alignItems="center"
        >
          <InstructionsSlider onStepCompleted={onStepCompleted} />
        </Stack>
      </Stack>
      <Stack
        sx={{ maxWidth: "1440px", width: "100%", mt: 7 }}
        direction="row"
        justifyContent="flex-end"
        spacing={7}
      >
        <Tooltip
          enterTouchDelay={0}
          title={hasReadItems ? "" : t("home.secondStep.readEntireContent")}
        >
          <Box>
            <Button
              variant="contained"
              sx={{ alignSelf: "flex-end" }}
              onClick={handleStepValidation}
              disabled={!hasReadItems}
            >
              {t("common.action.nextStep")}
            </Button>
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
