import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Flags from "./Flag";
import { CaretIcon } from "./Icon";

const languageToFlag = (language: string) => {
  if (language.includes("en")) return <Flags country="UK" height={24} />;
  if (language.includes("nl")) return <Flags country="NL" height={24} />;
  if (language.includes("fr")) return <Flags country="FR" height={24} />;
  if (language.includes("de")) return <Flags country="DE" height={24} />;
  return <Box sx={{ height: 24, width: 24 }}>DEV</Box>;
};

export default function LanguageSelector() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { i18n } = useTranslation();
  const open = Boolean(anchorEl);

  const handleClickButton = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleClickButton}
        variant="outlined"
        sx={{ py: 0.5, px: 3, borderColor: "#E9E9E9" }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          {languageToFlag(i18n.language)}
          <Box>
            <CaretIcon />
          </Box>
        </Stack>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          key="EN"
          selected={i18n.language.includes("en")}
          onClick={() => handleChangeLanguage("en-GB")}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Flags country="UK" height={24} />
            <Typography sx={{ pt: 1 }}>English</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          key="FR"
          selected={i18n.language.includes("fr")}
          onClick={() => handleChangeLanguage("fr-FR")}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Flags country="FR" height={24} />
            <Typography sx={{ pt: 1 }}>Français</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          key="NL"
          selected={i18n.language.includes("nl")}
          onClick={() => handleChangeLanguage("nl-NL")}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Flags country="NL" height={24} />
            <Typography sx={{ pt: 1 }}>Nederlands</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          key="DE"
          selected={i18n.language.includes("de")}
          onClick={() => handleChangeLanguage("de-DE")}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Flags country="DE" height={24} />
            <Typography sx={{ pt: 1 }}>Deutsch</Typography>
          </Stack>
        </MenuItem>
        {process.env.REACT_APP_ENABLE_DEV_LANGUAGE && (
          <MenuItem
            key="dev"
            selected={i18n.language.includes("dev")}
            onClick={() => handleChangeLanguage("dev")}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: 24, width: 24 }}
              >
                🧑‍💻
              </Stack>
              <Typography sx={{ pt: 1 }}>DEV</Typography>
            </Stack>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
