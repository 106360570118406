import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
// import Link from "@mui/material/Link";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Breadcrumb } from "../types";
import { useTranslation } from "react-i18next";
// import { normalizeKey } from "../config/i18n";

declare type Props = {
  children: JSX.Element | JSX.Element[];
  title: string;
  breadcrumbs?: Breadcrumb[];
};

export default function PageLayout({ title, children, breadcrumbs }: Props) {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{ pt: { xs: 6, sm: 13 } }}
      direction="row"
      justifyContent="center"
    >
      {/* {breadcrumbs && breadcrumbs?.length > 0 && (
        <Stack sx={{ height: 60 }} justifyContent="center">
          <Breadcrumbs separator="›">
            {breadcrumbs.map((item, index) =>
              index === breadcrumbs.length - 1 ? (
                <Typography variant="body2" sx={{ display: "inline" }}>
                  {t(normalizeKey(item.label))}
                </Typography>
              ) : (
                <Link
                  variant="body2"
                  underline="hover"
                  color="inherit"
                  onClick={item.onClick}
                  sx={{ fontWeight: 400 }}
                >
                  {t(normalizeKey(item.label))}
                </Link>
              )
            )}
          </Breadcrumbs>
        </Stack>
      )} */}
      {/* {title && <Typography variant="h1">{title}</Typography>} */}
      <Box
        sx={{
          maxWidth: "1440px",
          width: "100%",
          boxSizing: "border-box",
          px: { xs: 4, sm: 8, md: 23 },
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}
