import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

declare type Props = {
  open: boolean;
  handleClose: () => void;
  link?: string;
};

export default function ShowLinkDialog({ open, handleClose, link }: Props) {
  const { t } = useTranslation();
  const [buttonIcon, setButtonIcon] = useState(<ContentCopyIcon />);

  const handleClick = () => {
    navigator.clipboard.writeText(link!);
    setButtonIcon(<CheckIcon />);
    setTimeout(() => {
      setButtonIcon(<ContentCopyIcon />);
    }, 1000);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      scroll="paper"
      onClose={handleClose}
    >
      <DialogTitle>{t("app.link.title")}</DialogTitle>
      <DialogContent dividers={true}>
        <Stack spacing={6} alignItems="center">
          <DialogContentText
            color="text.primary"
            variant="body2"
            sx={{ whiteSpace: "pre-line", textAlign: "left" }}
          >
            {t("app.link.description")}
          </DialogContentText>
          <Box>
            <Tooltip title={t("app.link.tooltip")} arrow={true}>
              <Button
                onClick={handleClick}
                variant="outlined"
                sx={{ borderRadius: 4, textTransform: "initial" }}
                endIcon={buttonIcon}
              >
                <Stack direction="row" alignItems="center">
                  <Typography
                    variant="body2"
                    sx={{
                      maxWidth: { xs: 120, sm: 200, md: 300 },
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      p: 2,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {link}
                  </Typography>
                </Stack>
              </Button>
            </Tooltip>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common.action.close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
