import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { normalizeKey } from "../../../config/i18n";

export default function CompanyField({
  label,
  id,
  placeholder,
  ...rest
}: TextFieldProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <TextField
      placeholder={t("form.fields.company.placeholder")}
      label={t("form.fields.company.placeholder")}
      id="company"
      fullWidth
      error={!!errors?.company?.message}
      helperText={
        !!errors?.company?.message
          ? t(normalizeKey(errors?.company?.message as string))
          : " "
      }
      {...rest}
      {...register("company")}
    />
  );
}
