import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SyntheticEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useOnboarding from "../../../hooks/useOnboarding";
import emergencyInstructionsFR from "../../../config/translations/emergency/fr.json";
import emergencyInstructionsEN from "../../../config/translations/emergency/en.json";
import emergencyInstructionsDE from "../../../config/translations/emergency/de.json";
import emergencyInstructionsNL from "../../../config/translations/emergency/nl.json";

import Pictogram from "../../../components/Pictogram";
import CaptchaForm from "../../../components/CaptchaForm/CaptchaForm";

declare type Props = {
  onValidate: () => void;
};

export default function ThirdStep({ onValidate }: Props) {
  const { i18n, t } = useTranslation();
  const { consent, toggleConsent, captcha, setCaptcha, processing } =
    useOnboarding();

  const handleCheckboxChange = (event: SyntheticEvent, checked: boolean) => {
    toggleConsent(checked);
  };

  const finalInstructions = useMemo(() => {
    if (i18n.language.includes("fr")) return emergencyInstructionsFR;
    if (i18n.language.includes("en")) return emergencyInstructionsEN;
    if (i18n.language.includes("de")) return emergencyInstructionsDE;
    if (i18n.language.includes("nl")) return emergencyInstructionsNL;
    return emergencyInstructionsFR;
  }, [i18n.language]);

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography variant="h1" sx={{ textAlign: "center", mb: 2 }}>
        {t("home.thirdStep.title")}
      </Typography>
      <Typography sx={{ textAlign: "center", whiteSpace: "pre-line" }}>
        {t("home.thirdStep.description")}
      </Typography>
      <Stack
        sx={{
          mt: 6,
          py: 7,
          backgroundColor: "#F7F7F7",
          width: "100vw",
        }}
        alignItems="center"
      >
        <Stack
          sx={{
            maxWidth: "1440px",
            width: "100%",
            boxSizing: "border-box",
            px: { xs: 4, sm: 8, md: 23 },
          }}
          alignItems="center"
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            flex={1}
            sx={{ width: "100%" }}
            spacing={5}
          >
            <Stack flex={2} spacing={6}>
              {finalInstructions.map((instruction) => (
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  spacing={4}
                  sx={{ width: "100%" }}
                  key={instruction.text}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: { xs: 80, md: 100 },
                      height: { xs: 80, md: 100 },
                    }}
                  >
                    {instruction.picture && (
                      <Box
                        sx={{
                          width: { xs: 80, md: 100 },
                          height: { xs: 80, md: 100 },
                        }}
                      >
                        <Pictogram src={instruction.picture} />
                      </Box>
                    )}
                  </Stack>
                  <Typography
                    variant="body2"
                    sx={{
                      whiteSpace: "pre-line",
                      flex: 2,
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    {instruction.text}
                  </Typography>
                </Stack>
              ))}
              <Typography
                variant="h6"
                sx={{ whiteSpace: "pre-line", textAlign: "center" }}
              >
                {t("home.thirdStep.emergencyStayCalm")}
              </Typography>
            </Stack>

            <Stack flex={2} alignItems="center" justifyContent="center">
              <img
                src="/images/plan.png"
                alt="Plan du site"
                style={{ maxWidth: "100%" }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            mt: 6,
            maxWidth: "1440px",
            width: "100%",
            boxSizing: "border-box",
            px: { xs: 4, sm: 8, md: 23 },
          }}
          spacing={3}
        >
          <FormControlLabel
            onChange={handleCheckboxChange}
            control={<Checkbox checked={consent} />}
            label={`${t("home.thirdStep.consentText")} *`}
            componentsProps={{
              typography: { sx: { fontSize: 14, fontWeight: 400, ml: 2 } },
            }}
            sx={{ ml: 0 }}
          />
          <CaptchaForm captcha={captcha} setCaptcha={setCaptcha} />
          <Typography variant="caption">
            *{t("form.common.mandatoryFields")}
          </Typography>
        </Stack>
      </Stack>
      <Button
        variant="contained"
        sx={{ alignSelf: "flex-end", mt: 4 }}
        type="submit"
        disabled={!consent || !captcha || processing}
      >
        {t("common.action.validate")}
      </Button>
    </Stack>
  );
}
