import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import CompanyField from "./Onboarding/CompanyField";
import EmailField from "./Onboarding/EmailField";
import FirstnameField from "./Onboarding/FirstnameField";
import LastnameField from "./Onboarding/LastnameField";
import PhoneField from "./Onboarding/PhoneField";
import { useTranslation } from "react-i18next";

declare type Props = {
  mode?: "draft" | "user";
};

export default function IdentityForm({ mode = "user" }: Props) {
  const { t } = useTranslation();

  return (
    <Stack spacing={{ xs: 2, sm: 4 }}>
      <Grid
        container
        spacing={{ xs: 2, sm: 4 }}
        sx={{ "& > .MuiGrid-root": { pt: { xs: 1, sm: 3 } } }}
      >
        <Grid item xs={12} sm={6} order={1}>
          <LastnameField required={mode === "user"} />
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 2, sm: 3 }}>
          <FirstnameField required={mode === "user"} />
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 4, sm: 2 }}>
          <EmailField required={mode === "user"} />
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 5, sm: 4 }}>
          <PhoneField required={mode === "user"} />
        </Grid>
        <Grid item xs={12} sm={6} order={{ xs: 3, sm: 5 }}>
          <CompanyField required={mode === "user"} />
        </Grid>
      </Grid>
      {mode === "user" && (
        <Typography variant="caption">
          *{t("form.common.mandatoryFields")}
        </Typography>
      )}
    </Stack>
  );
}
