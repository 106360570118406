import * as yup from "yup";
import { NOW } from "../config";

export const OnboardingSchema = yup.object({
  firstname: yup
    .string()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s-]*)$/,
      "form.fields.firstname.format"
    )
    .max(50, "form.fields.firstname.max")
    .required("form.fields.firstname.required"),
  lastname: yup
    .string()
    .max(50, "form.fields.lastname.max")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s-]*)$/,
      "form.fields.lastname.format"
    )
    .required("form.fields.lastname.required"),
  email: yup
    .string()
    .max(100, "form.fields.email.max")
    .email("form.fields.email.format")
    .required("form.fields.email.required"),
  company: yup
    .string()
    .max(80, "form.fields.company.max")
    .required("form.fields.company.required"),
  phone: yup
    .string()
    .nullable()
    .optional()
    .max(25, "form.fields.phone.max")
    .required("form.fields.phone.required"),
  employee_firstname: yup
    .string()
    .max(50, "form.fields.employee_firstname.max")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s-]*)$/,
      "form.fields.employee_firstname.format"
    )
    .required("form.fields.employee_firstname.required"),
  employee_lastname: yup
    .string()
    .max(50, "form.fields.employee_lastname.max")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s-]*)$/,
      "form.fields.employee_lastname.format"
    )
    .required("form.fields.employee_lastname.required"),
  employee_company: yup
    .string()
    .required("form.fields.employee_company.required"),
  employee_email: yup
    .string()
    .max(100, "form.fields.employee_email.max")
    .email("form.fields.employee_email.format")
    .required("form.fields.employee_email.required"),
  appointment: yup
    .date()
    .min(NOW, "form.fields.appointment.min")
    .required("form.fields.appointment.required"),
  acceptTerms: yup.bool().oneOf([true], "form.fields.acceptTerms.required"),
});

export const DraftOnboardingSchema = yup.object({
  firstname: yup
    .string()
    .nullable()
    .optional()
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s-]*)$/,
      "form.fields.firstname.format"
    )
    .max(50, "form.fields.firstname.max"),
  lastname: yup
    .string()
    .nullable()
    .optional()
    .max(50, "form.fields.lastname.max")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s-]*)$/,
      "form.fields.lastname.format"
    ),
  email: yup
    .string()
    .nullable()
    .optional()
    .max(100, "form.fields.email.max")
    .email("form.fields.email.format"),
  company: yup
    .string()
    .nullable()
    .optional()
    .max(80, "form.fields.company.max"),
  phone: yup.string().nullable().optional().max(25, "form.fields.phone.max"),
  employee_firstname: yup
    .string()
    .nullable()
    .optional()
    .max(50, "form.fields.employee_firstname.max")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s-]*)$/,
      "form.fields.employee_firstname.format"
    ),
  employee_lastname: yup
    .string()
    .nullable()
    .optional()
    .max(50, "form.fields.employee_lastname.max")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s-]*)$/,
      "form.fields.employee_lastname.format"
    ),
  employee_company: yup.string().nullable().optional(),
  employee_email: yup
    .string()
    .nullable()
    .optional()
    .max(100, "form.fields.employee_email.max")
    .email("form.fields.employee_email.format"),
  appointment: yup
    .date()
    .nullable()
    .optional()
    .transform((curr, orig) => (orig === "" ? undefined : curr))
    .min(NOW, "form.fields.appointment.min"),
});
