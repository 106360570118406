import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

declare type Props = {
  direction: "left" | "right";
  onClick: () => void;
};

export default function InstructionArrow({ direction, onClick }: Props) {
  return (
    <Stack
      justifyContent="center"
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: direction === "left" ? 0 : "auto",
        right: direction === "right" ? 0 : "auto",
        cursor: "pointer",
        width: 35,
        backgroundColor: "transparent",
        zIndex: 2,
        color: "#7E7E7E",
        "&:hover": {
          color: "#A0A09B",
        },
      }}
      onClick={() => {
        console.log(" ATCHA ");
        onClick();
      }}
    >
      <Typography
        sx={{
          fontFamily: "Oooh Baby",
          fontWeight: "initial",
          fontSize: 100,
          color: "inherit",
        }}
      >
        {direction === "left" ? "<" : ">"}
      </Typography>
    </Stack>
  );
}
