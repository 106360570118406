import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IdentityForm from "../../../components/forms/IdentityForm";
import AppointmentForm from "../../../components/forms/AppointmentForm";
import TermsField from "../../../components/forms/Onboarding/TermsField";

declare type Props = {
  onValidate: () => void;
};

export default function FirstStep({ onValidate }: Props) {
  const { t } = useTranslation();

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography variant="h1" sx={{ textAlign: "center", mb: 2 }}>
        {t("home.firstStep.title")}
      </Typography>
      <Typography sx={{ textAlign: "center", whiteSpace: "pre-line" }}>
        {t("home.firstStep.description")}
      </Typography>
      <Stack
        sx={{
          mt: 6,
          py: 7,
          backgroundColor: "#F7F7F7",
          width: "100vw",
        }}
        alignItems="center"
      >
        <Box
          sx={{
            maxWidth: "1440px",
            width: "100%",
            px: { xs: 4, sm: 8, md: 23 },
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h2">
            {t("home.firstStep.identifyForm.title")}
          </Typography>
          <IdentityForm />
        </Box>
      </Stack>
      <Stack sx={{ maxWidth: "1440px", width: "100%", mt: 7 }}>
        <Typography variant="h2">
          {t("home.firstStep.employeeForm.title")}
        </Typography>
        <AppointmentForm />
      </Stack>
      <Stack
        sx={{ maxWidth: "1440px", width: "100%", mt: 7 }}
        direction="column"
        spacing={7}
      >
        <TermsField />
        <Button
          variant="contained"
          sx={{ alignSelf: "flex-end" }}
          onClick={onValidate}
        >
          {t("form.action.formNextStep")}
        </Button>
      </Stack>
    </Stack>
  );
}
