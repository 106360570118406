import { Dayjs } from "dayjs";
import { create } from "zustand";

export declare type OnboardingForm = {
  user: {
    firstname: string;
    lastname: string;
    email: string;
    language: string;
    company: string;
    phone: string;
  };
  employee: {
    firstname: string;
    lastname: string;
    email: string;
    company: string;
  };
  appointment: Date;
  validationDate: Dayjs;
};

declare type State = {
  processing: boolean;
  hasReadItems: boolean;
  consent: boolean;
  captcha: string;
  submittedValues?: OnboardingForm;
  toggleConsent: (newStatus: boolean) => void;
  setProcessing: (newStatus: boolean) => void;
  setCaptcha: (newCaptcha: string) => void;
  setSubmittedValues: (newValues: OnboardingForm) => void;
  markItemsAsRead: () => void;
  resetOnboarding: () => void;
};

const useOnboarding = create<State>((set) => ({
  hasReadItems: false,
  consent: false,
  captcha: "",
  processing: false,
  submittedValues: undefined,
  setProcessing: (newStatus) => set({ processing: newStatus }),
  setCaptcha: (newCaptcha) => set({ captcha: newCaptcha }),
  setSubmittedValues: (newValues) => set({ submittedValues: { ...newValues } }),
  toggleConsent: (newStatus) => set({ consent: newStatus }),
  markItemsAsRead: () => set({ hasReadItems: true }),
  resetOnboarding: () => set({ hasReadItems: false, consent: false }),
}));

export default useOnboarding;
