import MenuItem from "@mui/material/MenuItem";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { normalizeKey } from "../../../config/i18n";

export default function VPKCompanyField({
  label = "employee_company",
  id = "employee_company",
  name = "employee_company",
  placeholder,
  ...rest
}: TextFieldProps) {
  const {
    register,
    formState: { errors, defaultValues },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <TextField
      select
      placeholder={t("form.fields.employee_company.placeholder")}
      label={t("form.fields.employee_company.placeholder")}
      id="company"
      fullWidth
      SelectProps={{
        defaultValue: defaultValues?.employee_company,
        displayEmpty: true,
        sx: {
          height: 41,
          fontSize: "14px",
          lineHeight: "29px",
          py: 0,
        },
      }}
      error={!!errors[name]?.message}
      helperText={
        !!errors[name]?.message
          ? t(normalizeKey(errors[name]?.message as string))
          : " "
      }
      {...rest}
      {...register(name)}
    >
      <MenuItem value="" disabled>
        <em>{t("form.fields.employee_company.emptyLabel")}</em>
      </MenuItem>
      <MenuItem value="PAPER">VPK PAPER NORMANDIE</MenuItem>
      <MenuItem value="PACKAGING">VPK PACKAGING ALIZAY</MenuItem>
      <MenuItem value="GROUP">VPK GROUP</MenuItem>
    </TextField>
  );
}
