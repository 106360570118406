import { Typography } from "@mui/material";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { normalizeKey } from "../config/i18n";
import { Breadcrumb } from "../types";

declare type Props = {
  breadcrumbs: Breadcrumb[];
};

export default function Breadcrumbs({ breadcrumbs }: Props) {
  const { t } = useTranslation();

  if (breadcrumbs.length < 1) return null;
  return (
    <MUIBreadcrumbs separator="/">
      {breadcrumbs.map((breadcrumb, i) =>
        i === breadcrumbs.length - 1 ? (
          <Typography variant="body2" key={breadcrumb.label}>
            {t(normalizeKey(breadcrumb.label))}
          </Typography>
        ) : (
          <Typography
            key={breadcrumb.label}
            variant="body2"
            onClick={breadcrumb.onClick}
            sx={{
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {t(normalizeKey(breadcrumb.label))}
          </Typography>
        )
      )}
    </MUIBreadcrumbs>
  );
}
