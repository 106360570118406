import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";

export default function TermsField() {
  const { t } = useTranslation();
  const { register, formState, watch } = useFormContext();

  const { errors } = formState;

  const checked = watch("acceptTerms");

  return (
    <FormControl required error={!!errors?.acceptTerms?.message}>
      <FormControlLabel
        control={<Checkbox checked={checked} {...register("acceptTerms")} />}
        label={`${t("home.firstStep.acceptTerms")} *`}
        componentsProps={{
          typography: { sx: { fontSize: 14, fontWeight: 400, ml: 2 } },
        }}
        sx={{ ml: 0 }}
      />
      {!!errors?.acceptTerms?.message && (
        <FormHelperText color="error">
          {t("form.fields.acceptTerms.required")}
        </FormHelperText>
      )}
    </FormControl>
  );
}
