import { Breadcrumb } from "../../types";

const homeBreadcrumbs = (
  formStep: number,
  setFormStep: (formStep: number) => void
): Breadcrumb[] => {
  return [
    {
      label: "app.breadcrumbs.home",
      onClick: () => setFormStep(0),
    },
    ...(formStep > 0
      ? [
          {
            label: "app.breadcrumbs.instructions",
            onClick: () => setFormStep(1),
          },
        ]
      : []),
    ...(formStep > 1
      ? [
          {
            label: "app.breadcrumbs.map",
            onClick: () => setFormStep(2),
          },
        ]
      : []),
    ...(formStep > 2
      ? [
          {
            label: "app.breadcrumbs.validation",
            onClick: () => setFormStep(3),
          },
        ]
      : []),
  ];
};

export default homeBreadcrumbs;
