import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import { DraftOnboardingSchema } from "../../utils/form";
import PageLayout from "../PageLayout";
import AppointmentForm from "../../components/forms/AppointmentForm";
import IdentityForm from "../../components/forms/IdentityForm";
import ShowLinkDialog from "../../components/Dialogs/ShowLinkDialog";

export default function RegistrationPage() {
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const [showLinkDialog, setShowLinkDialog] = useState(false);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: "Accueil",
        onClick: () => navigate("/"),
      },
      {
        label: "Pré-inscription",
        onClick: () => navigate("/registration"),
      },
    ]);
  }, [setBreadcrumbs, navigate]);

  const [formattedLink, setFormattedLink] = useState<string | undefined>();
  const methods = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      company: "",
      phone: "",
      employee_company: "",
      employee_firstname: "",
      employee_lastname: "",
      employee_email: "",
      appointment: "",
    },
    mode: "all",
    resolver: yupResolver(DraftOnboardingSchema),
  });
  const { t } = useTranslation();

  const onSubmit = (data: any) => {
    const encodedDraft = window.btoa(JSON.stringify(data));
    const qParams = new URLSearchParams({ draft: encodedDraft });
    setFormattedLink(
      window.location.origin + "?" + decodeURIComponent(qParams.toString())
    );
    setShowLinkDialog(true);
  };

  return (
    <PageLayout title={t("welcome.pageTitle")}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack justifyContent="center" alignItems="center">
            <Typography variant="h1" sx={{ textAlign: "center", mb: 2 }}>
              {t("registration.title")}
            </Typography>
            <Typography sx={{ textAlign: "center", whiteSpace: "pre-line" }}>
              {t("registration.description")}
            </Typography>
            <Stack
              sx={{
                mt: 6,
                py: 7,
                backgroundColor: "#F7F7F7",
                width: "100vw",
              }}
              alignItems="center"
            >
              <Box
                sx={{
                  maxWidth: "1440px",
                  width: "100%",
                  px: { xs: 4, sm: 8, md: 23 },
                  boxSizing: "border-box",
                }}
              >
                <Typography variant="h2">
                  {t("registration.identifyFormTitle")}
                </Typography>
                <IdentityForm mode="draft" />
              </Box>
            </Stack>
            <Stack sx={{ maxWidth: "1440px", width: "100%", mt: 7 }}>
              <Typography variant="h2">
                {t("registration.employeeFormTitle")}
              </Typography>
              <AppointmentForm mode="draft" />
            </Stack>
            <Stack
              sx={{ maxWidth: "1440px", width: "100%", mt: 7 }}
              direction="column"
              alignItems="flex-end"
              spacing={7}
            >
              <Button color="primary" variant="contained" type="submit">
                {t("form.submitBtn.generateLink")}
              </Button>
            </Stack>
          </Stack>
          {formattedLink && (
            <ShowLinkDialog
              link={formattedLink}
              open={showLinkDialog}
              handleClose={() => setShowLinkDialog(false)}
            />
          )}
        </form>
      </FormProvider>
    </PageLayout>
  );
}
