import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import FirstnameField from "./Onboarding/FirstnameField";
import LastnameField from "./Onboarding/LastnameField";
import { useTranslation } from "react-i18next";
import VPKCompanyField from "./Onboarding/VPKCompanyField";
import EmailField from "./Onboarding/EmailField";
import DateTimeField from "./Onboarding/DateTimeField";

declare type Props = {
  mode?: "draft" | "user";
};

export default function AppointmentForm({ mode = "user" }: Props) {
  const { t } = useTranslation();

  return (
    <Stack spacing={{ xs: 2, sm: 4 }}>
      <Grid container spacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12} sm={6}>
          <LastnameField
            required={mode === "user"}
            name="employee_lastname"
            id="employee_lastname"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FirstnameField
            required={mode === "user"}
            name="employee_firstname"
            id="employee_firstname"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VPKCompanyField
            required={mode === "user"}
            name="employee_company"
            id="employee_company"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EmailField
            required={mode === "user"}
            name="employee_email"
            id="employee_email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimeField
            required={mode === "user"}
            name="appointment"
            id="appointment"
          />
        </Grid>
      </Grid>
      {mode === "user" && (
        <Typography variant="caption">
          *{t("form.common.mandatoryFields")}
        </Typography>
      )}
    </Stack>
  );
}
