import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function TermsNL() {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Editor
        </Typography>
        <Typography variant="body1">
          De website "Accueil Sécurité du site d'Alizay" wordt uitgegeven door
          VPK Paper Normandie
        </Typography>
        <Typography variant="body2">
          SASU VPK Paper Normandie
          <br />
          Siret : 79046297200023
          <br />
          APE : 1712Z
        </Typography>
        <Typography variant="body2">
          VPK Paper Normandie
          <br />
          Zone lndustrielle du Clos Pré BP 4<br />
          27460 Alizay
          <br />
          FRANCE - FRANKRIJK
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Creatie van de website
        </Typography>
        <Typography variant="body1">
          Dit vereenvoudigde onthaalplatform werd ontwikkeld door{" "}
          <Link href="lauriedupuis.fr" target="_blank">
            Laurie Dupuis
          </Link>
          .
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Afbeeldingen en illustraties
        </Typography>
        <Typography variant="body1">
          Pictogrammen gemaakt door Freepik & Smashicons, bronnen beschikbaar op
          Flaticon:
          <br />-{" "}
          <Link href="https://www.flaticon.com/authors/Freepik" target="_blank">
            https://www.flaticon.com/authors/Freepik
          </Link>
          <br />-{" "}
          <Link
            href="https://www.flaticon.com/authors/smashicons"
            target="_blank"
          >
            https://www.flaticon.com/authors/smashicons
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
}
