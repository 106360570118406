import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enTranslations from "./translations/en.json";
import frTranslations from "./translations/fr.json";
import nlTranslations from "./translations/nl.json";
import deTranslations from "./translations/de.json";

export const normalizeKey = (key: string) =>
  key as unknown as TemplateStringsArray;

export const resources = {
  en: { translation: enTranslations },
  fr: { translation: frTranslations },
  nl: { translation: nlTranslations },
  de: { translation: deTranslations },
};

i18next.use(LanguageDetector).use(initReactI18next).init({
  debug: false,
  resources,
  lng: "fr",
  fallbackLng: "dev",
});

declare module "i18next" {
  interface CustomTypeOptions {
    resources: typeof resources["fr"];
  }
}
