import { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CachedIcon from "@mui/icons-material/Cached";

import { useCaptcha } from "../../services/api";
import { useTranslation } from "react-i18next";

declare type Props = {
  captcha: string;
  setCaptcha: (captcha: string) => void;
};

export default function CaptchaForm({ captcha, setCaptcha }: Props) {
  const { isLoading, isFetching, data, refetch, isError } = useCaptcha();
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCaptcha(event.target.value);
  };

  return (
    <Stack spacing={3}>
      <Stack alignItems="flex-start">
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          {t("home.thirdStep.captcha.intro")}
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack
            sx={{ width: 200, height: 100 }}
            alignItems="center"
            justifyContent="center"
          >
            {(isLoading || isFetching) && <CircularProgress />}
            {!isLoading && !isFetching && data && (
              <img src={data.image} alt="CAPTCHA" />
            )}
            {isError && (
              <Typography color="error" variant="subtitle2">
                {t("home.thirdStep.captcha.error")}
              </Typography>
            )}
          </Stack>
          {!isLoading && !isFetching && data && (
            <IconButton
              color="primary"
              component="label"
              onClick={() => refetch()}
            >
              <CachedIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <TextField
        value={captcha}
        onChange={handleChange}
        required
        label={t("home.thirdStep.captcha.label")}
        sx={{ maxWidth: 200 }}
      />
    </Stack>
  );
}
