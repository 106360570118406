import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function TermsDE() {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Editor
        </Typography>
        <Typography variant="body1">
          Die Website "Accueil Sécurité du site d'Alizay" wird von VPK Paper
          Normandie herausgegeben
        </Typography>
        <Typography variant="body2">
          SASU VPK Paper Normandie
          <br />
          Siret : 79046297200023
          <br />
          APE : 1712Z
        </Typography>
        <Typography variant="body2">
          VPK Paper Normandie
          <br />
          Zone lndustrielle du Clos Pré BP 4<br />
          27460 Alizay
          <br />
          FRANCE - FRANKREICH
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Erstellung der Website
        </Typography>
        <Typography variant="body1">
          Diese vereinfachte Empfangsplattform wurde von{" "}
          <Link href="lauriedupuis.fr" target="_blank">
            Laurie Dupuis
          </Link>{" "}
          entwickelt.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Bilder und Illustrationen
        </Typography>
        <Typography variant="body1">
          Von Freepik & Smashicons erstellte Symbole, auf Flaticon verfügbare
          Ressourcen:
          <br />-{" "}
          <Link
            href="https://www.flaticon.com/de/autoren/Freepik"
            target="_blank"
          >
            https://www.flaticon.com/de/autoren/Freepik
          </Link>
          <br />-{" "}
          <Link
            href="https://www.flaticon.com/de/autoren/smashicons"
            target="_blank"
          >
            https://www.flaticon.com/de/autoren/smashicons
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
}
