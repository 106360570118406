import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LanguageSelector from "../components/LanguageSelector";
import Footer from "../components/Footer";
import useBreadcrumbs from "../hooks/useBreadcrumbs";
import Breadcrumbs from "../components/Breadcrumbs";
import ScrollToTop from "../components/ScrollToTop";

export default function Layout() {
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <>
      <ScrollToTop />
      <Stack alignItems="center" sx={{ overflowX: "hidden" }}>
        <Box sx={{ width: "100%" }}>
          <Stack justifyContent="center" direction="row">
            <Stack
              direction="row"
              alignItems="center"
              flex={1}
              sx={{
                borderBottom: "1px solid #E9E9E9",
                px: 2,
                py: 0.5,
                maxWidth: 1440,
              }}
            >
              <Box sx={{ flex: { xs: 2, md: "initial" } }}>
                <img
                  src="/vpk_logo.png"
                  alt="VPK Group logo"
                  style={{ maxWidth: 120 }}
                />
              </Box>

              <Stack
                flex={2}
                justifyContent="center"
                sx={{ display: { xs: "none", md: "flex" }, pl: 4 }}
              >
                {breadcrumbs && breadcrumbs.length > 0 && (
                  <Breadcrumbs breadcrumbs={breadcrumbs} />
                )}
              </Stack>
              <LanguageSelector />
            </Stack>
          </Stack>
          <Outlet />
          <Footer />
        </Box>
      </Stack>
    </>
  );
}
