import axios from "axios";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import PageLayout from "../PageLayout";
import homeBreadcrumbs from "./breadcrumbs";
import FirstStep from "./steps/FirstStep";
import { OnboardingSchema } from "../../utils/form";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";
import useOnboarding from "../../hooks/useOnboarding";
import { submitOnboarding } from "../../services/api";
import FinalStep from "./steps/FinalStep";

axios.defaults.withCredentials = true;

const parseParams = (params: URLSearchParams) => {
  try {
    if (params.get("draft")) {
      const decoded = window.atob(params.get("draft")!);
      const parsed = JSON.parse(decoded);
      return parsed;
    }
  } catch (error) {
    return {};
  }
  return {};
};

export default function HomePage() {
  const { t, i18n } = useTranslation();
  const { t: apit } = useTranslation("translation", { keyPrefix: "api" });
  const [formStep, setFormStep] = useState(0);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { captcha, setProcessing, setSubmittedValues, submittedValues } =
    useOnboarding();
  let [searchParams] = useSearchParams();

  const draft = parseParams(searchParams);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setBreadcrumbs(homeBreadcrumbs(formStep, setFormStep));
  }, [formStep, setBreadcrumbs]);

  const methods = useForm({
    defaultValues: {
      firstname: draft.firstname || "",
      lastname: draft.lastname || "",
      email: draft.email || "",
      company: draft.company || "",
      phone: draft.phone || "",
      employee_company: draft.employee_company || "",
      employee_firstname: draft.employee_firstname || "",
      employee_lastname: draft.employee_lastname || "",
      employee_email: draft.employee_email || "",
      acceptTerms: false,
      appointment: draft.appointment
        ? dayjs(draft.appointment).format("YYYY-MM-DDTHH:mm")
        : "",
    },
    mode: "all",
    resolver: yupResolver(OnboardingSchema),
  });

  const onSubmit = (data: any) => {
    if (!submittedValues) {
      setProcessing(true);
      const user = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        language: i18n.language.toUpperCase(),
        company: data.company,
        phone: data.phone,
      };
      const employee = {
        firstname: data.employee_firstname,
        lastname: data.employee_lastname,
        email: data.employee_email,
        company: data.employee_company,
      };
      const appointment = data.appointment;
      submitOnboarding({ user, employee, appointment, captcha })
        .then(() => {
          setSubmittedValues({
            user,
            employee,
            appointment,
            validationDate: dayjs(),
          });
          handleSetNextStep();
        })
        .catch((e) => {
          console.log(" ERR ", e?.response?.data?.code);
          if (e?.response?.data?.code) {
            enqueueSnackbar(apit(e?.response?.data?.code), {
              variant: "error",
            });
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      handleSetNextStep();
    }
  };

  const handleSetNextStep = () => {
    const currentStep = formStep;
    setFormStep(currentStep + 1);
    window.scrollTo(0, 0);
  };

  const handleSetPreviousStep = () => {
    const currentStep = formStep;
    setFormStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const validateFormStep = async () => {
    const result = await methods.trigger(undefined, { shouldFocus: true });
    if (result) {
      handleSetNextStep();
    }
  };

  return (
    <PageLayout
      title={t("welcome.pageTitle")}
      breadcrumbs={homeBreadcrumbs(formStep, setFormStep)}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {formStep === 0 && <FirstStep onValidate={validateFormStep} />}
          {formStep === 1 && <SecondStep onValidate={handleSetNextStep} />}
          {formStep === 2 && <ThirdStep onValidate={handleSetNextStep} />}
          {formStep === 3 && <FinalStep />}
          {process.env.REACT_APP_ENABLE_TOOLBAR && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ backgroundColor: "#E3E3E3", p: 3, mt: 5 }}
              spacing={2}
            >
              <Button disabled={formStep === 0} onClick={handleSetPreviousStep}>
                -
              </Button>
              <Typography>{formStep}</Typography>
              <Button onClick={handleSetNextStep}>+</Button>
            </Stack>
          )}
        </form>
      </FormProvider>
    </PageLayout>
  );
}
