import { create } from "zustand";
import { Breadcrumb } from "../types";

declare type State = {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (newBreadcrumb: Breadcrumb[]) => void;
  appendBreadcrumb: (newBreadcrumb: Breadcrumb) => void;
};

const useBreadcrumbs = create<State>((set) => ({
  breadcrumbs: [],
  setBreadcrumbs: (newBreadcrumbs) => set({ breadcrumbs: [...newBreadcrumbs] }),
  appendBreadcrumb: (newBreadcrumb) =>
    set((state) => ({ breadcrumbs: [...state.breadcrumbs, newBreadcrumb] })),
  // increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  // removeAllBears: () => set({ bears: 0 }),
}));

export default useBreadcrumbs;
