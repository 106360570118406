import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function GDPREN() {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="body1">
          VPK Paper Normandie attaches great importance to the protection of the
          personal data of its visitors.
        </Typography>
        <Typography variant="body1">
          We are responsible for processing this data. These provisions are
          intended to inform you of the way in which we use and protect your
          personal data as well as the reasons for which we process this data.
        </Typography>
        <Typography variant="body1">
          It is recalled that new European provisions on data protection will
          come into force from May 25, 2018. This is the European Regulation of
          April 27, 2016 which establishes rules relating to the protection of
          natural persons with regard to the processing of personal data and the
          rules relating to the free movement of such data.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Data controller
        </Typography>
        <Typography variant="body2">
          DPO - Data Protection Officer
          <br />
          VPK Paper Normandie
          <br />
          Zone lndustrielle du Clos Pré BP 4<br />
          27460 Alizay
          <br />
          FRANCE
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Data collected as data controller
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie acts as the data processing manager for its Users
          and as such collects data for service purposes.
        </Typography>
        <Typography variant="body1">
          We collect all or part of the following data:
        </Typography>
        <Typography variant="body2">
          - Company
          <br />
          - Mail address
          <br />
          - Full name
          <br />
          - Phone
          <br />- Site visit date
        </Typography>
        <Typography variant="body1">
          This data is entered directly by the User when registering, VPK Paper
          Normandie does not collect any personal data implicitly.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Objectif des données personnelles collectées
        </Typography>
        <Typography variant="body2">
          - On-site visitor safety and compliance
          <br />- To communicate with future visitors
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Communication of the User's personal data
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie may share the data collected with VPK Group,
          public bodies (Police, Justice, Tax Services, Social Services, etc.)
          upon official request and within the limits and compliance with the
          legal procedures applicable in France.
        </Typography>
        <Typography variant="body1">
          VPK Paper Normandie does not provide or sell its Users' data to any
          other organization or company and does not have its Users' data
          processed by subcontractors.
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          User rights
        </Typography>
        <Typography variant="body1">
          In accordance with the regulations in force, the User has various
          rights, namely:
        </Typography>
        <Typography variant="body2">
          Right of access (chapter 3 article 15 of the GDPR)
          <br />
          Right of rectification (chapter 3 article 16 of the GDPR)
          <br />
          Right to be forgotten (chapter 3 article 17 of the GDPR)
          <br />
          Right to restriction of processing (Chapter 3 Article 18 GDPR)
          <br />
          Right of opposition (chapter 3 article 21 of the GDPR)
          <br />
          Right to withdraw your consent (Chapter 2 Article 7 GDPR)
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Duration of processing and retention of data
        </Typography>
        <Typography variant="body1">
          Maximum 1 year for visitors and contractors.
        </Typography>
      </Stack>
    </Stack>
  );
}
