import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function TermsFR() {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Editeur
        </Typography>
        <Typography variant="body1">
          Le site web "Accueil Sécurité du site d’Alizay" est édité par VPK
          Paper Normandie
        </Typography>
        <Typography variant="body2">
          SASU VPK Paper Normandie
          <br />
          Siret : 79046297200023
          <br />
          APE : Fabrication de papier et de carton (1712Z)
        </Typography>
        <Typography variant="body2">
          VPK Paper Normandie
          <br />
          Zone lndustrielle du Clos Pré BP 4<br />
          27460 Alizay
          <br />
          FRANCE
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Création du site
        </Typography>
        <Typography variant="body1">
          Cette plateforme d’accueil simplifiée a été développée par{" "}
          <Link href="lauriedupuis.fr" target="_blank">
            Laurie Dupuis
          </Link>
          .
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Images et illustrations
        </Typography>
        <Typography variant="body1">
          Icônes créées par Freepik & Smashicons, ressources disponibles sur
          Flaticon :
          <br />-{" "}
          <Link
            href="https://www.flaticon.com/fr/auteurs/Freepik"
            target="_blank"
          >
            https://www.flaticon.com/fr/auteurs/Freepik
          </Link>
          <br />-{" "}
          <Link
            href="https://www.flaticon.com/fr/auteurs/smashicons"
            target="_blank"
          >
            https://www.flaticon.com/fr/auteurs/smashicons
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
}
