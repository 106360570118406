import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

declare type Initialization = {
  image: string;
};

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
});

export const useCaptcha = (config: UseQueryOptions<Initialization> = {}) =>
  useQuery({
    queryKey: ["captcha"],
    queryFn: (): Promise<Initialization> =>
      axiosApiInstance.get("/public/captcha").then((res) => res.data),
    retry: 3,
    staleTime: 10 * 60 * 1000,
    ...config,
  });

export const submitOnboarding = (data: any) =>
  axiosApiInstance.post("/public/submit", { ...data });
