import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { normalizeKey } from "../../../config/i18n";

export default function EmailField({
  label = "email",
  id = "email",
  name = "email",
  placeholder,
  ...rest
}: TextFieldProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <TextField
      placeholder={t("form.fields.email.placeholder")}
      label={t("form.fields.email.placeholder")}
      id={id}
      fullWidth
      type="email"
      error={!!errors[name]?.message}
      helperText={
        !!errors[name]?.message
          ? t(normalizeKey(errors[name]?.message as string))
          : " "
      }
      {...rest}
      {...register(name)}
    />
  );
}
